const baseUrl = "https://api.advancedparking.tech"

export default {
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    },

    get(url, onSuccess, onError, token) {
        fetch(baseUrl + url )
        .then(this.handleErrors)
        .then(response => response.json())
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError();
            }
        });
    },

    post(url, body, onSuccess, onError) {
        fetch(baseUrl + url, {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        })
        .then(response => response.json())
        .then(data => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    
    getWebKioskBySiteId(organisationId, siteId, onSuccess, onError) {
        this.get(`/${organisationId}/web-kiosk/${siteId}`, onSuccess, onError);
    },

    getWebKioskByShortCode(organisationId, siteId, kioskShortCode, onSuccess, onError) {
        this.get(`/${organisationId}/web-kiosk/${siteId}/${kioskShortCode}`, onSuccess, onError)
    },

    postWebKiosk(organisationId, siteId, siteKioskId, vrm, timeSpan, onSuccess, onError) {
        this.post(`/${organisationId}/web-kiosk/${siteId}/${siteKioskId}`, { vrm, timeSpan }, onSuccess, onError);
    }

}