import { useEffect, useState } from "react"
import Loader from "./shared/loader";
import api from "../utilities/api";
import { useParams } from "react-router-dom";
import stringUtilities from "../utilities/stringUtilities";
import moment from 'moment';

const TimeSpanModeEnum = {0: "Minutes", 1: "Hours", 2: "Days", 3: "End of Day"};

export default function Kiosk(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [data, setData] = useState(null);
    const [result, setResult] = useState(null);

    const [vrm, setVrm] = useState("");
    const [timespan, setTimespan] = useState(0);
    const [canSubmit, setCanSubmit] = useState(false);

    const { organisationId, siteId, kioskShortCode } = useParams();

    const doError = function () {
        setIsLoading(false);
        setIsError(true);
    }

    const submit = function () {
        setIsLoading(true);
        if (data) {
            api.postWebKiosk(organisationId, siteId, data.kioskId, vrm, parseInt(timespan), (data) => {
                setResult(data);
                setVrm(null);
                setTimespan(0);
            }, () => {
    
            })
        }
    }

    useEffect(() => {
        setCanSubmit(!stringUtilities.isNullOrEmpty(vrm));
    }, [vrm]);

    useEffect(() => {
        if (kioskShortCode) {
            api.getWebKioskByShortCode(organisationId, siteId, kioskShortCode, (data) => {
                if (data && data !== null) {
                    setData(data);
                    setIsLoading(false);
                    setTimespan(0)
                    setTimeout(() => {
                        document.getElementById("vrm")?.focus();
                    }, 500)
                } else {
                    doError();
                }
            }, () => {
                doError();
            })
        } else {
            api.getWebKioskBySiteId(organisationId, siteId, (data) => {
                if (data && data !== null) {
                    setData(data);
                    setIsLoading(false);
                    setTimespan(0)
                    setTimeout(() => {
                        document.getElementById("vrm")?.focus();
                    }, 500)
                } else {
                    doError();
                }
            }, () => {
                doError();
            })
        }
    }, [])

    return <>
        {(!data || data === null) && !isError && isLoading && <Loader />}
        {(!data || data === null) && isError && <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-column align-items-center mb-5">
                        <p className="text-danger mb-5 mt-5">Invalid URL</p>
                    </div>
                </div>
            </div>
        </div>}
        {data && data !== null && !isError &&
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex flex-column align-items-center mb-5">
                            <img src={data.settings.logo.url} alt={data.organisation.name} style={{ maxWidth: "300px" }} className="mt-3 mb-3" />
                            <h1 className="h4 text-center mb-5">{data.site.name}</h1>

                            {result && result !== null && <>

                                <i className="fas fa-check text-success mb-4" style={{ fontSize: "50px" }}></i>
                                <h2 className="h5 text-center mb-4">
                                    Thank you!
                                </h2>
                                <table>
                                    <tr>
                                        <td className="px-3 py-1">Valid until</td>
                                        <td className="py-1"><b>{moment(result.endDate).format("DD/MM/yyyy [at] HH:mm")}</b></td>
                                    </tr>
                                    <tr>
                                        <td className="px-3 py-1">VRM</td>
                                        <td className="py-1"><b>{result.vrm}</b></td>
                                    </tr>
                                    {!stringUtilities.isNullOrEmpty(result.vehicleMake) &&
                                        <tr>
                                            <td className="px-3 py-1">Vehicle</td>
                                            <td className="py-1"><b>{result.vehicleMake} {result.vehicleModel} {!stringUtilities.isNullOrEmpty(result.vehicleColour) ? `(${result.vehicleColour})` : ""}</b></td>
                                        </tr>
                                    }
                                </table>
                            </>}

                            {(!result || result === null) && <>
                                {!isLoading && <>

                                    <div className="form-group mb-5 d-flex flex-column align-items-center">
                                        <label className="form-label">Please enter your Vehicle Registration Number</label>
                                        <input id="vrm" className="form-control small-number-plate" type="text" value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase().replace(/ /g, ""))} maxLength={7} />
                                    </div>

                                    {data.timeSpans && data.timeSpans !== null && data.timeSpans.length > 1 &&
                                        <div className="form-group mb-5 d-flex flex-column align-items-center">
                                            <label className="form-label">Select the duration of your stay</label>
                                            <div>
                                                {data.timeSpans.map((ts, index) =>
                                                    <div key={index} class="custom-control custom-radio" index={index}>
                                                        <input class="custom-control-input" id={`timespan-${index}`} type="radio" name="timespan" value={index} checked={timespan === index} onChange={(e) => setTimespan(parseInt(e.target.value))} />
                                                        <label class="custom-control-label" for={`timespan-${index}`}>
                                                            {ts.duration && ts.duration}&nbsp;
                                                            {
                                                                !ts.duration || ts.duration > 1 
                                                                ? TimeSpanModeEnum[ts.type] 
                                                                : TimeSpanModeEnum[ts.type].substring(0, TimeSpanModeEnum[ts.type].length - 1)
                                                            }
                                                        </label>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    }

                                    <button className="btn btn-lg btn-success" disabled={!canSubmit} onClick={submit}>Submit</button>
                                </>}

                                {isLoading && <div className="small mt-5 mb-3">
                                    <Loader />
                                </div>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}